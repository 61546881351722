import React, { useState, useEffect, useRef } from 'react';
import { Container, Nav, Navbar, Button, NavDropdown } from 'react-bootstrap';
import { FaBars } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { useGetSiteDataQuery } from '../../../redux-services/apis/siteDataApi';
import { Link, useLocation } from 'react-router-dom';
import { getCategoryList, getGearCategoryList, showModel } from '../../../redux-services/slices/siteDataSlice';
import HeaderCart from "./HeaderCart";
import AuthUserDetails from "./authUserDetails";
import { useGetDataBySlugQuery } from '../../../redux-services/apis/technologyApi';
import MetaData from '../../MetaData/MetaData';

const Header = ({ setScrollDown }) => {

  const dropdownDiv = useRef();

  const { _isLoading } = useGetSiteDataQuery();

  const location = useLocation();
  const categories = useSelector(getCategoryList);
  const gearCategoryList = useSelector(getGearCategoryList);

  const [metaDetails, setMetaDetails] = useState({});
  const { isLoading, data: getDataBySlug } = useGetDataBySlugQuery({
    slug: location?.pathname.replace('/', '') == '' ? 'home' : location?.pathname.split('/').at(-1) == "all" ? location?.pathname.split('/').at(1) : location?.pathname.split('/').at(1) == "shafts" || location?.pathname.split('/').at(1) == "gear" ? (location?.pathname.split('/').at(-1) + "-category") : location?.pathname.split('/').at(-1)
  });

  useEffect(() => {
    setMetaDetails(getDataBySlug?.response[0])
  });

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showModel({}))
    if (dropdownDiv && dropdownDiv?.current?.offsetParent?.classList.length)
      dropdownDiv.current.offsetParent.classList.remove('show')
  }, [location])

  const [isOpen, setIsOpen] = useState(false);
  const [isToggle, setToggle] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const toggle = () => setIsOpen(!isOpen);

  const isSticky = (e) => {
    const header = document.querySelector('header');
    const scrollTop = window.scrollY;
    scrollTop >= 250 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    (scrollTop > lastScrollTop) ? header.classList.add('is-scroll') : header.classList.remove('is-scroll')
    setLastScrollTop(scrollTop)
  };

  const handleClick = (e) => {

    setScrollDown(true);
    setIsOpen(false);
    // e.preventDefault();
    let ele1 = document.querySelector(".nav-item");
    ele1.classList.remove("show");

    let ele2 = document.querySelector("#basic-nav-dropdown");
    ele2.setAttribute("aria-expanded", false);
    let ele = document.querySelector("[aria-labelledby='basic-nav-dropdown']");
    ele.classList.remove("show");
    //setToggle(!isToggle);
  };

  const toggleMenu = (event) => {
    //setToggle(!isToggle);
    console.log("fffffffffffffffff")
  }

  return (
    <header id="header" className='myheader'>
      <MetaData metaData={metaDetails} />
      <Navbar expand="lg" className='w-100 p-0'>
        <Container>
          <Navbar.Brand key="log">
            <Link to="/">
              <img src="/images/logo.svg" alt='#' />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle key="title"
            aria-controls="basic-navbar-nav"
            onClick={() => toggle()}
          >
            <FaBars />
          </Navbar.Toggle>
          <Navbar.Collapse key="collapse"
            isopen={isOpen ? "true" : "false"}
            id="basic-navbar-nav"
          >
            <Nav className="ms-auto">
              <NavDropdown title="Store" id="basic-nav-dropdown">
                {/* <NavDropdown.Item >Action</NavDropdown.Item>
              <NavDropdown.Item >
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item >Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item >
                Separated link
              </NavDropdown.Item> */}
                <NavDropdown.Item ref={dropdownDiv}>
                  <div className='dropdownmain' >
                    <div key="shaftPart" className='shaftPart'>
                      <div className='submenu-outer'>
                        <ul className='submenuList'>
                          {categories.map((category) =>
                            category.total_products > 0 &&
                            <li key={'head_' + category._id}><Link key={category._id} to={"/shafts/category/" + category.slug} state={{ categoryId: category._id }} onClick={handleClick}>{category.name}</Link>
                              <ul className='meghaMenu'>
                                {category.products.map(product =>
                                  <li key={'product_' + product._id}>
                                    <Link key={product.slug} to={'/shafts/' + product.slug}>
                                      {product.product_title}
                                    </Link>
                                  </li>
                                )}
                              </ul>
                            </li>)}
                        </ul>
                      </div>
                    </div>
                    <div key="storePart" className='storePart'>
                      <ul className='storeMenu'>
                        <li className='gearheading'><Link to="/gear/category/all" state={{ categoryId: "gear" }}>Gear</Link></li>
                        {
                          gearCategoryList.map((category) => category?.products?.length > 0 && <>
                            <li key={category.name}><Link to={`/gear/category/` + category.slug} state={{ categoryId: category._id }}>{category.name}</Link></li>
                          </>
                          )}
                      </ul>
                    </div>
                  </div>
                </NavDropdown.Item>
              </NavDropdown>


              <Link to="/pages/technology">Technology</Link>
              <Link to="/pages/robot-lab">Robot Lab</Link>
              <li>
                <Nav.Link key="Store" to="">About</Nav.Link>
                <ul className='sortmenu'>
                  <li><Link to="/pages/kinetixx-technology">Kinetixx Technology</Link></li>
                  <li><Link to="/pages/composite-fiber-technology">Composite Fiber Technology</Link></li>
                  <li><Link to="/press-center">Press Center</Link></li>
                  <li><Link to="/contact">Contact Us</Link></li>
                  <li><Link to="/dealer">Dealers</Link></li>
                </ul>
              </li>
              <li>
                <Nav.Link key="/pages/Fitting" to="">Fitting</Nav.Link>
                <ul className='sortmenu'>
                  <li><Link to="kinetixx-fit">Kinetixx Fitting Tool</Link></li>
                  <li><Link to="product-comparison-tool">Product Comparison Tool</Link></li>
                  <li><Link to="launch-spin-profiles">Launch & Spin Profiles </Link></li>
                  <li><Link to="fitting-studios">Fitting Studios</Link></li>
                </ul>
              </li>
            </Nav>
          </Navbar.Collapse>
          <div className='cartprofile-outer d-flex'>
            <HeaderCart />
            <AuthUserDetails />
          </div>
          <Link to="kinetixx-fit" className="btn btn-primary">Kinetixx Fit</Link>

        </Container>
      </Navbar>
    </header >
  );
}

export default Header;
