import { Button, Col } from "react-bootstrap";
import CartProduct from "../Cart/CartProduct";
import CouponBox from "../Cart/CouponBox";
import OrderTotal from "../Cart/OrderTotal";

const CheckoutSideMenu = ({ cartData }) => {

    console.log("CheckoutSideMenu", cartData);
    return <Col md={12} lg={4}>
        <div className='paymentsidebar'>
            <ul className='cartlist'>
                {cartData?.products?.map((product) =>
                    <CartProduct key={product._id} cartId={cartData?.cart_id} product={product} isSideMenu={true} />
                )}

            </ul >

            <CouponBox cartData={cartData} />

            <div className='coupancodeouter mt-4'>
                <hr />
                <OrderTotal cartData={cartData} />

            </div>
        </div >
    </Col >
}

export default CheckoutSideMenu;