import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Container, Row, Col, Button, Breadcrumb, BreadcrumbItem, Form } from "react-bootstrap"
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer,
    PayPalHostedField,
    usePayPalHostedFields,
    PayPalHostedFieldsProvider
} from "@paypal/react-paypal-js";
import { useCapturePaypalOrderMutation, useCreatePaypalOrderMutation, useGenerateClientTokenQuery } from "../../redux-services/apis/paymentGetWay";
import Spiner from "../../components/Layouts/Spiner";
import { envData } from "../../constants";
import { PAYPAL_CLIENT_ID } from "../../config";

const clientTokenFields = {
    client_token: null,
    clientId: PAYPAL_CLIENT_ID,//envData.PAYPAL_CLIENT_ID,
    error: null
}

const PayPalButton = ({ amount, setPaymentResponse, setPaymentError }) => {
    //let payAmount = amount > 0 ? amount.toFixed(2) : 0;
    /**
     * usePayPalScriptReducer use within PayPalScriptProvider
     * isPending: not finished loading(default state)
     * isResolved: successfully loaded
     * isRejected: failed to load
     */
    const [loadingButton, setLoadingButton] = useState(true);
    const [{ isPending }] = usePayPalScriptReducer();
    const paypalbuttonTransactionProps = {
        style: { layout: "horizontal", tagline: true, color: "white", label: "pay", shape: "pill", height: 40 },
        createOrder(data, actions) {
            const ordersd = actions.order.create({
                purchase_units: [
                    {
                        amount: {
                            value: amount
                        }
                    }
                ],
                application_context: {
                    shipping_preference: "NO_SHIPPING",
                },
            });
            return ordersd
        },
        onApprove(data, actions) {
            /**
             * data: {
             *   orderID: string;
             *   payerID: string;
             *   paymentID: string | null;
             *   billingToken: string | null;
             *   facilitatorAccesstoken: string;
             * }
             */
            return actions.order.capture({}).then((details) => {
                setPaymentResponse(details)
                console.log("Data details: " + data, details);
            });
        },
        onError(err) {
            setPaymentError("Paypal Payment Error")
        },
        onInit(data, actions) {
            if (Object.keys(data).length > 0) {
                setLoadingButton(false);
            }
        }
    };
    return (
        <>
            {isPending ? <h2>Load Smart Payment Button...</h2> : null}
            <span style={{ backgroundColor: "black" }}>
                <Spiner customLoading={loadingButton} />
                {<PayPalButtons {...paypalbuttonTransactionProps} className="testtt" />}
            </span>
        </>
    );
}

const PayPalCreditCardPayment = ({ paypalRef, setPaymentResponse, isCreditCard, selectedBilling, amount, setPaymentError }) => {
    const cardHolderName = useRef(null);
    const [clientToken, setClientToken] = useState(clientTokenFields);

    const { data: clientTokenRes } = useGenerateClientTokenQuery();
    const [createPaypalOrder, paypalOrderResponse] = useCreatePaypalOrderMutation();

    useEffect(() => {
        if (clientTokenRes?.status) {
            setClientToken({ ...clientToken, client_token: clientTokenRes.response.client_token })
        }

    }, [clientTokenRes]);

    return (
        isCreditCard ? <div className='creditcardform'>
        </div> :
            <div>
                <PayPalScriptProvider options={{
                    "client-id": clientToken.clientId,
                    "data-client-token": clientToken.client_token,
                    components: "buttons",
                    currency: "USD",
                    vault: true,
                }}>
                    <PayPalButton amount={amount} setPaymentResponse={setPaymentResponse} setPaymentError={setPaymentError} />
                </PayPalScriptProvider>
            </div>
    )
}

export default PayPalCreditCardPayment;