import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Button, Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { AiFillStar } from 'react-icons/ai';
import { BsCurrencyDollar } from 'react-icons/bs';
import { MdOutlinePictureAsPdf } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Spiner from '../../../components/Layouts/Spiner';
import { DATE_TIME_OPTIONS, ORDER_LIST_PAYMENT_METHOD, ORDER_LIST_PAYMENT_STATUS, VIEW_ORDER_ORDER_STATUS } from '../../../constants';
import { useGetOrderDetailQuery } from '../../../redux-services/apis/userApi';
import { getUserDetails } from '../../../redux-services/slices/authSlice';
import CartProduct from '../../Cart/CartProduct';
import { CARDICON } from '../../../constants/CardConstant';
import ServiceCartProduct from '../../Cart/ServiceCartProduct';
import { getProductsList } from '../../../redux-services/slices/siteDataSlice';

const OrderDetails = () => {
  const params = useParams();

  const [order, setOrder] = useState(null);
  const [isCalling, setIsCalling] = useState(false);
  const productsList = useSelector(getProductsList);
  const { isLoading, data } = useGetOrderDetailQuery(
    {
      orderId: params?.orderId,
    },
    {
      skip: !isCalling,
    }
  );

  useEffect(() => {
    if (productsList.length > 0) {
      setIsCalling(true)
    }
  }, [productsList])

  useEffect(() => {
    if (data?.status && data.response) {
      setOrder(data.response);
    }
  }, [data]);
  console.log('ORDER', order);
  const authUser = useSelector(getUserDetails);


  const returnProduct = useCallback((productItem, productField) => {
    const product = productsList.find(product => product.sku.includes(productItem.product_sku) &&
      product.product_variation_id.includes(productItem.product_variation_id)
    )
    if (productField === "fitting_options") {
      if (product.categoryType === "OTHER") {
        return "Gear";
      }
      return productItem.is_customize ? "Customize Shaft" : "Raw Shaft";
    }
    return product[productField]
  }, [productsList])

  return isLoading ? (
    <Spiner />
  ) : (
    order && (
      <section className="checkoutpage">
        <Container>
          <Row>
            <Col md="12">
              <div className="accountInnner ps-0 pe-0">
                <h2>Order Details</h2>
                <div className="backpage">
                  <p>
                    {authUser?.title} {authUser?.first_name}{' '}
                    {authUser?.last_name}
                  </p>
                  <div>
                    <Link to="/order">Back to Your Orders</Link>
                  </div>
                </div>
                <hr />
              </div>
            </Col>
            <Col md="12">
              <div className="orderdetailsheader">
                <ul>
                  <li>
                    {new Intl.DateTimeFormat('en-US', DATE_TIME_OPTIONS).format(
                      new Date(order.ordered_on)
                    )}
                  </li>
                  <li>Order# {order.order_id}</li>
                </ul>
                {/* <Button className="downloadinvoice">
                  <MdOutlinePictureAsPdf />
                  Invoice
                </Button> */}
              </div>
              <div className="detailsoforder">
                <div className="shippingdetails">
                  <h5>Shipping Address</h5>
                  <p>{order?.shipping_address?.name}</p>
                  <p>
                    {order?.shipping_address?.address_line_1}
                    <p>{order?.shipping_address?.address_line_2}</p>
                    <p>
                      {order?.shipping_address.shipping_address_detail.city}
                    </p>
                    <span>
                      {order?.shipping_address.shipping_address_detail.state} -{' '}
                      {order?.shipping_address.shipping_address_detail.zipcode}{' '}
                    </span>{' '}
                    {order?.shipping_address.shipping_address_detail.country}
                    <p>
                      {order?.shipping_address?.ccode +
                        order?.shipping_address?.contact_no}
                    </p>
                  </p>
                </div>
                <div className="shippingdetails">
                  <h5>Billing Address</h5>
                  <p>{order?.billing_address?.name}</p>
                  <p>
                    {order?.billing_address?.address_line_1}
                    <p>{order?.billing_address?.address_line_2}</p>
                    <p>
                      {order?.billing_address.billing_address_detail.city}
                    </p>
                    <span>
                      {order?.billing_address.billing_address_detail.state} -{' '}
                      {order?.billing_address.billing_address_detail.zipcode}{' '}
                    </span>{' '}
                    {order?.billing_address.billing_address_detail.country}
                    <p>
                      {order?.billing_address?.ccode +
                        order?.billing_address?.contact_no}
                    </p>
                  </p>
                </div>
                <div className="shippingdetails">
                  <h5>Payment Information</h5>
                  <div className="paymentmathed">
                    <p>
                      <b>Payment Method</b>
                    </p>
                    {(order.payment_status === 2 || Object.keys(order?.payment_detail[0]?.card_detail).length > 0) ? Object.keys(order?.payment_detail[0]?.card_detail).length > 0 ? <span><img style={{ height: "20px" }} src={CARDICON[order?.payment_detail[0]?.card_detail.brand]} /> **** {order?.payment_detail[0]?.card_detail.last_digits}</span> : `${ORDER_LIST_PAYMENT_METHOD[order.payment_mode]}` : "---"}
                  </div>
                  <div className="paymentmathed mt-2">
                    <p>
                      <b>Payment Status</b>
                    </p>
                    <p>{ORDER_LIST_PAYMENT_STATUS[order?.payment_status]}</p>
                  </div>
                  {order?.payment_comment && <div className="paymentmathed mt-2">
                    <p>
                      <b>Payment Remark</b>
                    </p>
                    <p>{order?.payment_comment}</p>
                  </div>}
                  {((order?.order_logs?.length > 0 && order?.order_logs !== null && order?.order_logs[0].refund_notes !== null) && order?.order_status === 4) && <div className="paymentmathed mt-2">
                    <p>
                      <b>Refund Notes</b>
                    </p>
                    <p>{order?.order_logs[0]?.refund_notes}</p>
                  </div>}
                </div>
                <div className="shippingdetails">
                  <h5>Order Summary</h5>
                  <ul className="ordersummerydetails">
                    <li>
                      Product(s) Total:{' '}
                      <span>
                        <BsCurrencyDollar />{' '}
                        {order?.product_total?.$numberDecimal}
                      </span>
                    </li>
                    {order?.discounted_amount?.$numberDecimal &&
                      order?.discounted_amount?.$numberDecimal > 0 &&
                      order?.coupon_code ? (
                      <li>
                        Discount:{' '}
                        <span>
                          -<BsCurrencyDollar />{' '}
                          {order?.discounted_amount?.$numberDecimal}
                        </span>
                      </li>
                    ) : null}
                    {order?.fitting_total?.$numberDecimal &&
                      Number(order?.fitting_total?.$numberDecimal) > 0 && (
                        <li>
                          {' '}
                          Build Parameter(s) total:{' '}
                          <span>
                            <BsCurrencyDollar />{' '}
                            {order?.fitting_total?.$numberDecimal}
                          </span>
                        </li>
                      )}
                    {order?.tax_applied &&
                      Number(order?.tax_total?.$numberDecimal) > 0 ? (
                      <>
                        <li className="grandtotal">
                          Total Before Tax:{' '}
                          <span>
                            <BsCurrencyDollar />{' '}
                            {(Number(order?.fitting_total?.$numberDecimal) +
                              (Number(order?.product_total?.$numberDecimal) -
                                Number(order?.discounted_amount?.$numberDecimal))).toFixed(2)}
                          </span>
                        </li>
                        <li>
                          Sales Tax:{' '}
                          <span>
                            <BsCurrencyDollar />{' '}
                            {order?.tax_total?.$numberDecimal}
                          </span>
                        </li>
                      </>
                    ) : null}
                    {order?.shipping_mode?.totalCharges &&
                      Number(order?.shipping_mode?.totalCharges) > 0 ? (
                      <li>
                        {' '}
                        Shipping:{' '}
                        <span>
                          <BsCurrencyDollar />{' '}
                          {order?.shipping_mode?.totalCharges}
                        </span>
                      </li>
                    ) : null}
                    {order?.shipping_mode?.shipping_discount &&
                      Number(order?.shipping_mode?.shipping_discount) > 0 ? (
                      <li>
                        {' '}
                        Shipping Discount:{' '}
                        <span>
                          -<BsCurrencyDollar />{' '}
                          {order?.shipping_mode?.shipping_discount}
                        </span>
                      </li>
                    ) : null}
                    {order?.serviceProduct?.length > 0 && <li className="grandtotal">
                      Service/Labour:{' '}
                      <span>
                        <BsCurrencyDollar />{' '}
                        {order?.serviceProduct?.reduce((accumulator, currentValue) => accumulator + (currentValue.price * currentValue.quantity), 0)}
                      </span>
                    </li>}
                    {(order?.refunded_amount > 0) && <li className="grandtotal">
                      Refnded Amount:{' '}
                      <span>
                        -<BsCurrencyDollar />{' '}
                        {Number(order?.refunded_amount).toFixed(2)}
                      </span>
                    </li>}
                    <li className="grandtotal">
                      Grand Total:{' '}
                      <span>
                        <BsCurrencyDollar />{' '}
                        {order?.grand_total?.$numberDecimal}
                      </span>
                    </li>
                  </ul>
                </div>

              </div>

              {order.returnInitiates.length > 0 && <div className={'checkoutproductdetails'}>
                <div className={'check-out-panel-left'}>
                  <div className="product-table-main">
                    <h2>Returned Products Detail</h2>
                    <Table responsive striped="rows" bordered >
                      <thead style={{ verticalAlign: 'middle' }}>
                        <tr>
                          <th rowspan="2">Box Name</th>
                          <th colspan="4" style={{ textAlign: "center" }}>Products Detail</th>
                          <th rowspan="2">Date Of Return Request</th>
                          <th rowspan="2">Shipping Status</th>
                          <th rowspan="2">Refunded Amount</th>
                        </tr>
                        <tr>
                          <th>Product</th>
                          <th>Product Type</th>
                          <th>SKU</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody style={{ verticalAlign: 'middle' }}>
                        {order.returnInitiates.length > 0 && order.returnInitiates.map(
                          (item) => item.product_item.map(
                            (subItem, index) => (
                              (index === 0 ? <tr key={subItem.product_sku}>
                                <td className={item.product_item?.length} rowspan={item.product_item?.length > 0 ? `${item.product_item?.length}` : "1"}>{item.box_name}</td>

                                <td>{subItem.product_title + " - " + returnProduct(subItem, "categoryName")}</td>
                                <td>{returnProduct(subItem, "fitting_options")}</td>
                                <td>{subItem.product_sku}</td>
                                <td>{subItem.product_quantity}</td>
                                <td rowspan={item.product_item?.length > 0 ? `${item.product_item?.length}` : "1"}>{new Intl.DateTimeFormat(
                                  'en-US',
                                  DATE_TIME_OPTIONS
                                ).format(new Date(item.date_of_return))}</td>
                                <td rowspan={item.product_item?.length > 0 ? `${item.product_item?.length}` : "1"}>{VIEW_ORDER_ORDER_STATUS[item.return_status]}</td>
                                <td rowspan={item.product_item?.length > 0 ? `${item.product_item?.length}` : "1"}>{item.refunded_amount ? `$${item.refunded_amount}` : "--"}</td>
                              </tr> : <tr key={subItem.product_sku}>
                                <td>{subItem.product_title + " - " + returnProduct(subItem, "categoryName")}</td>
                                <td>{returnProduct(subItem, "fitting_options")}</td>
                                <td>{subItem.product_sku}</td>
                                <td>{subItem.product_quantity}</td>
                              </tr>
                              ))))}

                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>}
              {order.order_category !== "service_order" && <>
                {[...(order?.products ?? [])]
                  .sort((a, b) => (a.sku > b.sku ? 1 : -1))
                  ?.map((product) => (
                    <CartProduct
                      key={product._id}
                      product={product}
                      isOrder={true}
                      productTotal={order?.product_total?.$numberDecimal}
                      orderStatus={order?.order_status}
                    />
                  ))}
                <ServiceCartProduct
                  buildShopSupplyProduct={order?.buildShopSupplyProduct ?? []}
                  serviceProduct={order?.serviceProduct ?? []}
                />
              </>}

              {(order.order_category === "service_order" && (order?.buildShopSupplyProduct.length > 0 || order?.serviceProduct.length > 0)) && <ServiceCartProduct
                buildShopSupplyProduct={order?.buildShopSupplyProduct ?? []}
                serviceProduct={order?.serviceProduct ?? []}
              />
              }
            </Col>
          </Row>
        </Container>
      </section>
    )
  );
};

export default OrderDetails;
