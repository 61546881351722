import { Container, Row, Col, Button, Breadcrumb, BreadcrumbItem, Form } from "react-bootstrap"
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ShowModel from "../Notifications/ShowModel";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useGetOrderDetailQuery } from "../../redux-services/apis/userApi";
import Spiner from "../../components/Layouts/Spiner";
import { useDispatch, useSelector } from "react-redux";
import { getFittingList, showModel } from "../../redux-services/slices/siteDataSlice";
import { useCapturePaypalOrderMutation, useCreatePaypalOrderMutation, useGenerateClientTokenQuery, useGenerateTokenQuery } from "../../redux-services/apis/paymentGetWay";
import dropin, { Dropin, PaymentMethodPayload } from 'braintree-web-drop-in';
import { useDoPaymentMutation } from "../../redux-services/apis/cartApi";
import { getUserDetails, setPreviousLocation } from "../../redux-services/slices/authSlice";
import { doLogout } from "../../redux-services/slices/authSlice";
import { BiHelpCircle, BiLock } from "react-icons/bi";
import { useRef } from "react";
import PayPalCreditCardPayment from "./PayPalCreditCardPayment";
import { useGetUserAddressQuery } from "../../redux-services/apis/userApi";
import AddressList from "../Shipping/AddressList";
import CreditCardForm from "./CreditCardForm";
const DUMMY_IMAGE = '/images/productdummy.png';
const AWS_STORAGE_URL = process.env.REACT_APP_AWS_STORAGE_URL;
//import createClient from "braintree-web/client";

const tokenFields = {
    clientToken: null,
    success: false,
    instance: null,
    payment_method_nonce: null,
    error: ''
}
const clientTokenFields = {
    client_token: null,
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
    error: null
}


const PayNow = () => {
    const { order_id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const cardHolderName = useRef(null);
    const paypalRef = useRef(null);

    const userDetail = useSelector(getUserDetails);

    const [order, setOrder] = useState(null);
    const [orderId, setOrderId] = useState("");
    const [loadToken, setLoadToken] = useState(true);
    const [loadAddress, setLoadAddress] = useState(true);
    const [productModel, setProductModel] = useState(null);
    const [serviceProduct, setServiceProduct] = useState([]);
    const [priceCalculation, setPriceCalculation] = useState({
        productTotal: 0,
        discount: 0,
        buildParameterTotal: 0,
        TotalBeforeTax: 0,
        shipping: 0,
        salesTax: 0,
        shippingDiscount: 0,
        netShippingAmount: 0,
        grandTotal: 0,
        dueAmount: 0,
        totalPayment: 0,
        service_price: 0,
    });

    const fittingList = useSelector(getFittingList);
    const { data: billingAddress } = useGetUserAddressQuery({}, { skip: loadAddress });
    const { isLoading, data } = useGetOrderDetailQuery(
        {
            orderId: orderId,
        },
        {
            skip: !orderId,
        }
    );
    const [doPayment, doPaymentResponse] = useDoPaymentMutation();


    const [tokenDetails, setTokenDetails] = useState(tokenFields);
    const [isCreditCard, setIsCreditCard] = useState(false);
    const [paymentResponse, setPaymentResponse] = useState(null);
    const [addressList, setAddressList] = useState([]);
    const [isBillingAdressSame, setIsBillingAdressSame] = useState(false);
    const [selectedBillingAddress, setBillingAddress] = useState(null);
    const [selectedBilling, setSelectedBilling] = useState(null);
    const [paymentError, setPaymentError] = useState(null);
    const [cardDetail, setCardDetail] = useState({
        cardNumber: '',
        cardName: '',
        expiryDate: '',
        cvc: '',
        isCardSave: true,
        savedCardId: '',
        paymentType: '',
    });
    const [onSubmit, setOnSubmit] = useState(false);

    const { data: getBrainTreeToken } = useGenerateTokenQuery({}, { skip: loadToken });

    useEffect(() => {
        if (paymentResponse) {
            const formData = {
                orderId: order._id,
                paymentResponse,
                selectedBilling,
                ptype: searchParams.get("ptype")
            }
            doPayment(formData)
            console.log(paymentResponse, "paymentResponse")
        }
    }, [paymentResponse])

    useEffect(() => {
        if (onSubmit === true) {
            let payDetail = { ...cardDetail, paymentType: "credit_card" }
            const formData = {
                orderId: order._id,
                paymentResponse: payDetail,
                selectedBilling,
                ptype: searchParams.get("ptype")
            }
            setOnSubmit(false)
            doPayment(formData)
        }
    }, [onSubmit])

    useEffect(() => {
        if (billingAddress?.response) {
            const selectedAddress = billingAddress.response.find(item => item._id === order.billing_address.add_id);
            if (selectedAddress !== undefined) {
                setBillingAddress(selectedAddress._id)
                setIsBillingAdressSame(true)
                setSelectedBilling(selectedAddress)
            }
            if (order.billing_address && selectedAddress === undefined) {
                setBillingAddress(order.billing_address.add_id)
                setIsBillingAdressSame(true)
                setSelectedBilling(order.billing_address)
            }
            setAddressList(billingAddress.response);
        }
    }, [billingAddress]);

    useEffect(() => {
        if (paymentError) {
            dispatch(
                showModel({
                    isOpen: true,
                    modelType: 'danger',
                    body: paymentError,
                })
            );
        }
    }, [paymentError])

    useEffect(() => {
        if (selectedBillingAddress) {
            const selectedAddress = addressList.find(item => item._id === selectedBillingAddress);
            if (selectedAddress !== undefined) {
                setBillingAddress(selectedAddress._id)
                setIsBillingAdressSame(true)
                setSelectedBilling(selectedAddress)
            }
        }
    }, [selectedBillingAddress])

    /*  useEffect(() => {
         if (clientTokenRes?.status) {
             console.log(clientTokenRes.response.client_token, "sssssssssssssssssssssssss")
             setClientToken({ ...clientToken, client_token: clientTokenRes.response.client_token })
         }
 
     }, [clientTokenRes]); */

    /*  useEffect(() => {
         if (isCreditCard === true) {
             createPaypalOrder({ currency_code: "USD", value: "10.00" });
         }
     }, [isCreditCard]) */

    /* useEffect(() => {
        // isSuccess
        if (paypalOrderResponse.isSuccess) {
            setCreateOrderId(paypalOrderResponse?.data?.response.id)
        }
        console.log(paypalOrderResponse, "lllllllllllllllllll")
    }, [paypalOrderResponse]) */

    useEffect(() => {
        if (userDetail) {
            setOrderId(order_id);
        }
    }, [userDetail])

    useEffect(() => {
        if (doPaymentResponse?.isSuccess) {
            if (doPaymentResponse.data?.response?.link_expires === false) {
                navigate({
                    pathname: '/thank-you',
                    search: `?order_id=${doPaymentResponse.data?.response?.order_id}`,
                });
            } else {
                navigate("/link-expired");
            }
        }
        if (doPaymentResponse?.isError === true) {
            dispatch(
                showModel({
                    isOpen: true,
                    modelType: 'danger',
                    body: doPaymentResponse.error.data.message,
                })
            );
        }
    }, [doPaymentResponse]);

    /*  useEffect(() => {
         if (getBrainTreeToken?.status === true) {
 
             setTokenDetails((newData) => ({ ...newData, clientToken: getBrainTreeToken?.response?.clientToken }))
 
             // loadBrainTreePayment(getBrainTreeToken?.response?.clientToken)
 
         }
     }, [getBrainTreeToken]) */

    useEffect(() => {
        if (data?.status && data.response) {
            if (data.response.user_id === userDetail?._id) {

                if (data.response?.payment_status !== 2) {
                    setLoadToken(false)
                    setLoadAddress(false)
                    setOrder(data.response);
                    if (data.response.order_category !== "service_order") {
                        setProducts(data.response.products)
                        const serviceOrder = [...data.response?.buildShopSupplyProduct ?? [], ...data.response?.serviceProduct ?? []];
                        setServiceProduct(serviceOrder)
                    } else {
                        const serviceOrder = [...data.response.buildShopSupplyProduct, ...data.response.serviceProduct];
                        setServiceProduct(serviceOrder)
                    }
                    productPriceCalculation(data.response)
                } else {
                    navigate("/link-expired");
                }
            } else {
                dispatch(doLogout())
                dispatch(setPreviousLocation(`${location.pathname}${location.search}`))
                navigate("/login", {
                    state: `${location.pathname}${location.search}`
                });
            }
        }
    }, [data, fittingList]);

    const loadBrainTreePayment = (token) => {
        const config = {
            authorization: token,
            container: '#braintree-drop-in-div',
            vaultManager: true,
            card: {
                cardholderName: true,
                vault: {
                    vaultCard: true,
                    allowVaultCardOverride: true
                }
            },
            paypal: {
                flow: 'vault',
                amount: searchParams.get("ptype") === "1" ? priceCalculation.grandTotal : priceCalculation.dueAmount,
                currency: 'USD',
            }
        }

        /**
       * InitializeBraintree Method Callback
       * @param error
       * @param instance
       */
        const callback = (error, instance) => {
            if (error) console.error(error);
            else setTokenDetails((setInstance) => ({ ...setInstance, instance: instance }));
        };
        const initializeBraintree = () => dropin.create(config, callback);
        if (tokenDetails.instance) {
            tokenDetails.instance.teardown().then(() => {
                initializeBraintree();
            });
        } else {
            initializeBraintree();
        }
    }

    const requestPaymentMethod = () => {
        /**
         * RequestPaymentMethod Callback
         * @param error
         * @param payload
         */
        const callback = (error, payload) => {
            if (error) {
                console.error(error);
            } else {
                const paymentMethodNonce = payload.nonce;
                const formData = {
                    paymentMethodNonce,
                    orderId: order._id,
                    ptype: searchParams.get("ptype")
                }
                doPayment(formData)
                console.log('payment method nonce', payload.nonce);
            }
        };

        tokenDetails.instance && tokenDetails.instance.requestPaymentMethod(callback);
    };



    const productPriceCalculation = (priceData) => {
        setPriceCalculation({
            ...priceCalculation,
            productTotal: priceData.product_total?.$numberDecimal,
            discount: priceData?.discounted_amount?.$numberDecimal,
            buildParameterTotal: priceData?.fitting_total?.$numberDecimal,
            TotalBeforeTax: (
                Number(priceData?.fitting_total?.$numberDecimal) +
                (Number(priceData?.product_total?.$numberDecimal) -
                    Number(priceData?.discounted_amount?.$numberDecimal))
            ),
            salesTax: priceData?.tax_total?.$numberDecimal,
            shipping: priceData?.shipping_mode.totalCharges,
            shippingDiscount: priceData?.shipping_mode.shipping_discount,
            netShippingAmount: (Number(priceData?.shipping_mode.totalCharges) - Number(priceData?.shipping_mode.shipping_discount)),
            paidAmount: priceData?.paid_amount?.$numberDecimal,
            dueAmount: amountDue(Number(priceData?.grand_total?.$numberDecimal), Number(priceData?.paid_amount?.$numberDecimal), Number(priceData?.refunded_amount)),
            grandTotal: priceData?.grand_total?.$numberDecimal,
            totalPayment: searchParams.get("ptype") === "1" ? Number(priceData?.grand_total?.$numberDecimal).toFixed(2) : amountDue(Number(priceData?.grand_total?.$numberDecimal), Number(priceData?.paid_amount?.$numberDecimal), Number(priceData?.refunded_amount)),
            service_price: priceData?.serviceProduct?.reduce((prev, curr) => prev + (curr.price * curr.quantity), 0)
        })
    };
    const amountDue = (grandTotal, paidAmount, refundedAmount = 0) => {
        const refunded = (Number(grandTotal) + Number(refundedAmount))

        if (Number(refunded) > Number(paidAmount)) {
            return (Number(refunded) - Number(paidAmount)).toFixed(2)
        }
        return Number(0).toFixed(2);
    }

    const setProducts = (product) => {
        const productDetail = product.map(item => {
            const newProduct = { ...item };
            newProduct.thumbnail = (newProduct?.images?.images !== undefined && newProduct?.images?.images[0]) ? `${AWS_STORAGE_URL}/uploads/product/${newProduct?.images?.images[0]}` : newProduct.thumbnail ?? DUMMY_IMAGE;
            let productVariationDetail = [];
            let productFitting = [];

            Object.keys(newProduct?.product_variation ?? {}).map((vari) => {
                productVariationDetail.push({
                    attribute_name: vari,
                    term_option: newProduct.product_variation[vari],
                });
            });
            newProduct.productVariationSku = newProduct.sku;
            newProduct.price = newProduct.product_price?.$numberDecimal;
            productFitting = item?.fitting_options ?? [];

            newProduct.productVariationDetail = productVariationDetail.sort(
                (a, b) => {
                    return a?.attribute_name === 'Flex' ? -1 : 1;
                }
            );

            // setProductFittings
            const fittings = [];
            productFitting.map((productF) => {
                if (
                    productF.attribute_name.toLowerCase() == 'grips' ||
                    productF.attribute_name.toLowerCase() == 'adaptors'
                ) {
                    newProduct['has_customization'] = true;
                    const ft = {
                        ...productF,
                        image: getImage(productF.attribute_name.toLowerCase(), productF),
                    };
                    newProduct[productF.attribute_name.toLowerCase()] = ft;
                } else {
                    fittings.push(productF);
                }
            });

            newProduct.productFitting = fittings;
            return newProduct;
        })
        setProductModel(productDetail)
    };

    const getImage = (title, productF) => {
        const fittings = fittingList
            ?.find((ftt) => ftt.title.toLowerCase() === title)
            ?.fittings?.find((ft) => ft._id === productF.option_id);
        let image = '';
        if (title === 'grips') {
            image = fittings?.options?.find(
                (ft) => ft._id === productF.sub_option_id
            )?.image;
        } else if (title === 'adaptors') {
            image = fittings?.image;
        }
        return image
            ? `${AWS_STORAGE_URL}/uploads/fitting-variation/${image}`
            : DUMMY_IMAGE;
    };
    const radioCreditCardHandler = (status) => {
        setIsCreditCard(status);
    };
    const handleClick = () => {
        paypalRef.current.handleClick();
    };
    const changeAddress = () => {
        setIsBillingAdressSame(!isBillingAdressSame)
    }
    return (order && (<section className="informations">
        <Container>
            <Spiner />
            <Row>
                <Col md="12">
                    <div className="breadcrumbOuter">
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <Link to="/cart">Pay Now</Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                {' '}
                                <Link to="/checkout">Shipping</Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Payment</BreadcrumbItem>
                        </Breadcrumb>
                    </div>

                </Col>
                <ShowModel />
                <Col md={12} lg={8}>
                    <div className='infochanges'>
                        <h5>Shipping</h5>
                        <ul className='infolist'>
                            <li>
                                <h5>Ship to</h5>
                                <p>{order?.shipping_address?.name} ({`${order?.shipping_address?.ccode} ${order?.shipping_address?.contact_no}`}) {order?.shipping_address?.address}</p>
                            </li>
                            <li>
                                <h5>Method</h5>
                                {order?.shipping_mode?.totalCharges ? <p>{order?.shipping_mode?.carrier} ({order?.shipping_mode?.service}) . <strong>${order?.shipping_mode?.totalCharges}</strong></p> : <p>No Shipping</p>}
                            </li>
                        </ul>
                    </div>
                    <div className="contactinfo">
                        <div className="Contact-informations">
                            <div className="contacttop">
                                <div>
                                    <h5>Payment</h5>
                                    <p>All transactions are secure and encrypted.</p>
                                </div>
                            </div>
                            <ul className='addressList'>
                                <li className='cardouter'>
                                    <input type="radio" id="html" checked={!isCreditCard} name="fav_language" value="HTML" onClick={(e) => radioCreditCardHandler(false)} />
                                    <label for="html">
                                        <span className='fulladdress'> Paypal</span>
                                    </label>
                                </li>
                                <li className='cardouter'>
                                    <input type="radio" id="html" checked={isCreditCard} name="fav_language" value="HTML" onClick={(e) => radioCreditCardHandler(true)} />
                                    <label for="html">
                                        <span className='fulladdress'> Credit card</span>
                                        <span><img src="/images/visa.png" alt='#' /></span>
                                    </label>
                                </li>
                                {
                                    (isCreditCard && priceCalculation.totalPayment > 0) && <CreditCardForm
                                        paypalRef={paypalRef}
                                        setCardDetail={setCardDetail}
                                        cardDetail={cardDetail}
                                        setPaymentError={setPaymentError}
                                        setOnSubmit={setOnSubmit}
                                    />
                                }
                            </ul>
                        </div>
                        <div className="Contact-informations">
                            <div className="contacttop">
                                <div>
                                    <h5>Billing address</h5>
                                    <p>
                                        Select the address that matches your card or payment
                                        method.
                                    </p>
                                </div>
                            </div>
                            <div className='infochanges'>
                                <ul className='infolist'>
                                    <li>
                                        <h5>Billing to</h5>
                                        {selectedBilling ?
                                            <p>{selectedBilling?.name} ({`${selectedBilling?.ccode} ${selectedBilling?.contact_no}`}) {selectedBilling?.address}</p> :
                                            <p>{order.billing_address?.name} ({`${order.billing_address?.ccode} ${order.billing_address?.contact_no}`}) {order.billing_address?.address}</p>
                                        }
                                        <Button className="change-address" onClick={changeAddress}>{isBillingAdressSame ? "Change" : "Close"}</Button>
                                    </li>
                                </ul>
                                {!isBillingAdressSame && (
                                    <AddressList
                                        calculateShipping={false}
                                        setAddressId={setBillingAddress}
                                        addressId={selectedBillingAddress}
                                        addressList={addressList}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="Contact-informations">
                            <Row>
                                <Col md="12">
                                    <div className="d-flex justify-content-between align-items-center mt-1 mb-3">
                                        <Link to="/checkout" className="returnshopping">
                                            {' '}
                                        </Link>
                                        {
                                            (!isCreditCard && priceCalculation.totalPayment > 0) && <PayPalCreditCardPayment
                                                paypalRef={paypalRef}
                                                setPaymentResponse={setPaymentResponse}
                                                isCreditCard={isCreditCard}
                                                amount={priceCalculation.totalPayment}
                                                setPaymentError={setPaymentError}
                                            />
                                        }
                                        {isCreditCard && <Button onClick={handleClick}>Pay Now</Button>}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>

                </Col>
                <Col md={12} lg={4}>
                    <div className='paymentsidebar'>
                        <ul className='cartlist'>
                            {productModel && productModel.map((pModel) => (<li key={pModel?._id}>
                                <div className="cart-partleft">
                                    <div className="cartproductImg">
                                        <img
                                            src={pModel?.thumbnail ?? '/images/productdummy.png'}
                                            alt={pModel?.product_title}
                                            className="img-fluid"
                                        />
                                        <span className="productcount">{pModel?.quantity}</span>
                                    </div>
                                    <div className="cartproduct-content">
                                        <h5>
                                            {pModel?.product_title} - {pModel?.categoryName}
                                        </h5>
                                        <span>{pModel?.productVariationSku}</span>
                                    </div>
                                </div>
                                <div className="cart-partright">
                                    ${(Number(pModel?.price) * Number(pModel?.quantity)).toFixed(2)}
                                </div>
                                {pModel?.has_customization && (
                                    <ul className="cat-additional-pModel">
                                        {pModel?.adaptors && (
                                            <li key={pModel?.adaptors?._id}>
                                                <div className="cart-partleft">
                                                    <div className="cartproductImg">
                                                        <img
                                                            src={pModel?.adaptors.image}
                                                            alt={pModel?.adaptors?.attribute_name}
                                                            className="img-fluid"
                                                        />
                                                    </div>
                                                    <div className="cartproduct-content">
                                                        <h5>{pModel?.adaptors?.attribute_name}</h5>
                                                        <span>{pModel?.adaptors?.option_name}</span>
                                                        <span>{pModel?.adaptors?.sub_option_name}</span>
                                                    </div>
                                                </div>
                                                <div className="cart-partright">
                                                    ${pModel?.adaptors?.price?.toFixed(2)}
                                                </div>
                                            </li>
                                        )}
                                        {pModel?.grips && (
                                            <li key={pModel?.grips._id}>
                                                <div className="cart-partleft">
                                                    <div className="cartproductImg">
                                                        <img
                                                            src={pModel?.grips?.image}
                                                            alt={pModel?.grips?.attribute_name}
                                                            className="img-fluid"
                                                        />
                                                    </div>
                                                    <div className="cartproduct-content">
                                                        <h5>{pModel?.grips?.attribute_name}</h5>
                                                        <span>{pModel?.grips?.option_name}</span>
                                                        <span>{pModel?.grips?.sub_option_name}</span>
                                                    </div>
                                                </div>
                                                <div className="cart-partright">
                                                    ${pModel?.grips.price?.toFixed(2)}
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                )}
                            </li>))}
                            {serviceProduct && serviceProduct.map((pModel) => (<li key={pModel?._id}>
                                <div className="cart-partleft">
                                    <div className="cartproductImg">
                                        <img
                                            src={'/images/productdummy.png'}
                                            alt={pModel?.product_title}
                                            className="img-fluid"
                                        />
                                        <span className="productcount">{pModel?.quantity}</span>
                                    </div>
                                    <div className="cartproduct-content">
                                        <h5>
                                            {pModel?.product_title} - {Object.keys(pModel).includes("service_id") ? "Service" : "Build Shop Supply"}
                                        </h5>
                                    </div>
                                </div>
                                <div className="cart-partright">
                                    ${(Number(pModel?.price) * Number(pModel?.quantity)).toFixed(2)}
                                </div>
                            </li>))}
                        </ul>
                        <div className='coupancodeouter mt-4'>
                            <div className="paymentsummry">
                                <p >
                                    Coupon Code: <span style={{ color: "green" }}>{order?.coupon_code}</span>
                                </p>
                                <hr />
                                <p>
                                    Product(s) Total: <span>${Number(priceCalculation.productTotal).toFixed(2)}</span>
                                </p>
                                {priceCalculation.discount > 0 && (<p>
                                    Discount: <span>-${Number(priceCalculation.discount).toFixed(2)}</span>
                                </p>)}
                                {priceCalculation.buildParameterTotal > 0 && (<p>
                                    Build Parameter(s) total: <span>${Number(priceCalculation.buildParameterTotal).toFixed(2)}</span>
                                </p>)}
                                {priceCalculation.TotalBeforeTax > 0 && (<p className="grandtotal">
                                    Total Before Tax: <span>${Number(priceCalculation.TotalBeforeTax).toFixed(2)}</span>
                                </p>)}
                                {priceCalculation.salesTax > 0 && (<p>
                                    Sales Tax: <span>${Number(priceCalculation.salesTax).toFixed(2)}</span>
                                </p>)}
                                {priceCalculation.shipping > 0 && (<p>
                                    Shipping: <span>${Number(priceCalculation.shipping).toFixed(2)}</span>
                                </p>)}
                                {priceCalculation.shippingDiscount > 0 && (<p>
                                    Shipping Discount: <span>-${Number(priceCalculation.shippingDiscount).toFixed(2)}</span>
                                </p>)}
                                {(priceCalculation.netShippingAmount > 0 && priceCalculation.shippingDiscount > 0) && (<p>
                                    Net Shipping Amount: <span>${Number(priceCalculation.netShippingAmount).toFixed(2)}</span>
                                </p>)}
                                {(priceCalculation.paidAmount > 0 && priceCalculation.dueAmount > 0) && (<p>
                                    Paid Amount: <span>${Number(priceCalculation.paidAmount).toFixed(2)}</span>
                                </p>)}
                                {priceCalculation.service_price > 0 && (<p>
                                    Service/Labour: <span>${Number(priceCalculation.service_price).toFixed(2)}</span>
                                </p>)}
                                {priceCalculation.dueAmount > 0 && (<p>
                                    Due Amount: <span>${Number(priceCalculation.dueAmount).toFixed(2)}</span>
                                </p>)}
                                {priceCalculation.grandTotal > 0 && (<p className="grandtotal">
                                    Grand Total: <span>${priceCalculation.grandTotal}</span>
                                </p>)}
                            </div>
                        </div>
                    </div>

                </Col>
            </Row>
        </Container >
    </section >)
    );

}

export default PayNow;