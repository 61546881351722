import { FaUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom";
import { ApiService, TAGS } from "../../../redux-services/apis/apiService";
import { doLogout, getIsAuthenticated, getUserDetails } from "../../../redux-services/slices/authSlice";

const AuthUserDetails = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(getUserDetails);
    const isAuthenticated = useSelector(getIsAuthenticated);

    const logout = (e) => {
        e.preventDefault();
        dispatch(doLogout());
        dispatch(ApiService.util.invalidateTags(TAGS))
        navigate('/login');
    }

    return <div className={user === null ? "loginMenu enter-profile" : "enter-profile"}>
        <Link to={isAuthenticated ? "/profile" : "/login"}><img src="/images/login.png" alt='#' />{isAuthenticated ? <span className="clientname">{user.title} {" "} {user.first_name}</span> : null}</Link>
        {isAuthenticated && user ? <ul className='profilelist'>
            <span><FaUserCircle /> {user.title} {user.first_name} {'last_name' in user ? user.last_name : ""}</span>
            <li><Link to="/account">Account</Link></li>
            <li><Link to="/order">Orders</Link></li>
            <li><Link to="/wishlist">Wishlist</Link></li>
            <li><Link to="/address">Addresses</Link></li>
            <li><Link to="/save-card">Payment Methods</Link></li>
            {user?.userType !== 2 && <li><Link to="/resources">Resources</Link></li>}
            <li><Link onClick={logout} to="#">Log out</Link></li>
        </ul> : null}
    </div>
}

export default AuthUserDetails;